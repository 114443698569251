import Vue from "vue";
import Vuetify from "vuetify/lib";

import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
    } /* 
    themes: {
      light: {
        primary: "#675da1",
      },
      dark: {
        primary: "#675da1",
      },
    }, */,
  },
  icons: {
    iconfont: "mdi",
  },
});
