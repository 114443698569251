<template>
  <div v-bind:class="{'small':$vuetify.breakpoint.smAndDown}">
    <!-- Floating Chat Button -->
    <v-btn v-show="!isChatOpen"
      fab
      color="primary"
      class="floating-chat-btn"
      @click="toggleChat"
      elevation="4"
    >
      <v-badge
        :content="totalUnreadMessages"
        color="red"
        v-if="totalUnreadMessages > 0"
        overlap
      >
        <v-icon>mdi-message</v-icon>
      </v-badge>
      <v-icon v-else>mdi-message</v-icon>
    </v-btn>

    <!-- Chat Window -->
    <v-dialog
      v-model="isChatOpen"
      
      max-width="400px"
      hide-overlay
      transition="dialog-bottom-transition"
      content-class="chat-dialog-content"
      class="whoknowsho"
      id="chat-dialog"

      :fullscreen="$vuetify.breakpoint.smAndDown"

    >
      <v-card outlined> 
        <!-- Chat Header -->
        <v-toolbar flat class="chat-header pl-2">
          <v-btn icon v-if="selectedConversation" @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title class="pl-2">
            {{ selectedConversation ? getUserName(selectedConversation.id) : 'Mensajes' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeChat">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />

        <!-- Interactions List or Chat Window -->
        <v-card-text class="chat-content pa-0 pb-0 mb-0" v-bind:class="{'ismobile':$vuetify.breakpoint.smAndDown}">
          <!-- Interactions List -->
          <div v-if="!selectedConversation">
            <v-list class="conversation-list-content py-0">
              <template v-for="(conversation, index) in sortedInteractions">
                <v-list-item
                  :key="conversation.id"
                  @click="selectConversation(conversation)"
                >
                  <v-list-item-avatar>
                    <!-- Display user avatar or icon -->
                    <v-icon v-if="conversation.type == 'whatsapp'">mdi-whatsapp</v-icon>
                    <v-icon v-if="conversation.type == 'instagram'">mdi-instagram</v-icon>
                    <v-badge
                      v-show="conversation.type == 'in-app-chat'"
                      :content="' '"
                      :color="getStatusClass(conversation.id)"
                      overlap
                    >
                      <UserImage
                        v-if="conversation.type == 'in-app-chat'"
                        :user="{ id: conversation.id }"
                        xsmall
                      />
                    </v-badge>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ getUserName(conversation.id) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ conversation.lastMessage }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-badge
                      v-if="conversation.unreadCount > 0"
                      :content="conversation.unreadCount"
                      color="red"
                      overlap
                    >
                      <v-icon color="grey lighten-1">mdi-message</v-icon>
                    </v-badge>
                    <v-icon v-else color="grey lighten-1">mdi-message-outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </div>

          <!-- Chat Messages -->
          <div v-else>
            <div class="chat-messages">
              <div
                v-for="(message, index) in selectedConversation.messages"
                :key="index"
                :class="['message-container', message.fromUser ? 'from-user' : 'from-admin']"
              >
                <!-- Message Types -->
                <div v-if="message.type === 'text'">
                    <p
                    class="mb-1 px-2 py-1 rounded-lg" 
                    v-bind:style="{ backgroundColor: message.fromUser ? '#128c7e' : '#3f51b5', color:'white' }"
                  >
                    {{ message.text }}
                  </p>
                </div>
                <div v-else-if="message.type === 'image'">
                  <img
                    :src="message.fileUrl"
                    width="200"
                    class="mb-2"
                  />
                </div>
                <div v-else-if="message.type === 'video'">
                  <video
                    :src="message.fileUrl"
                    controls
                    width="200"
                    class="mb-2"
                  ></video>
                </div>
                <div v-else-if="message.type === 'file'">
                  <v-chip
                    class="mb-2"
                    :color="message.fromUser ? '#128c7e' : '#3f51b5'"
                    @click="downloadFile(message.fileUrl)"
                  >
                    <v-icon left>mdi-file</v-icon>
                    {{ message.fileName }}
                  </v-chip>
                </div>
              </div>
              <!-- Typing Indicator -->
              <div v-if="isUserTyping" class="typing-indicator">
                <v-chip class="mb-2">
                  {{ getUserName(selectedConversation.id) }} está escribiendo...
                </v-chip>
              </div>
            </div>
          </div>
        </v-card-text>

        <!-- Message Input Section -->
        <v-divider v-if="selectedConversation" />
        <v-card-actions class="message-input" v-if="selectedConversation">
          <v-progress-linear
            v-if="uploading"
            :value="uploadProgress"
            height="2"
            color="primary"
          ></v-progress-linear>
          <v-text-field
            v-model="newMessage"
            label="Escribe un mensaje"
            outlined
            
            hide-details
            class="rounded-lg"
            :disabled="!selectedConversation || uploading"
            @keyup.enter="sendMessage"
            @input="handleTyping"
          >
            <template v-slot:append>
              <v-icon @click="triggerFileInput">mdi-paperclip</v-icon>
              <v-icon @click="sendMessage" :color="newMessage ? 'primary' : ''">mdi-send</v-icon>
            </template>
          </v-text-field>
          <!-- Hidden File Input -->
          <input
            type="file"
            ref="fileInput"
            @change="handleFileUpload"
            style="display: none"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getDatabase,
  ref as dbRef,
  set,
  update,
  push,
  onValue,
  onDisconnect,
  get,
  off
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import UserImage from '@/components/profile/UserImage.vue';
import { setDoc, collection, getFirestore, addDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { logAuditEvent } from '@/error/audit.js';


import { Device } from '@capacitor/device';





export default {
  components: {
    UserImage,
  },
  data() {
    return {
      isChatOpen: false,
      interactions: [],
      selectedConversation: null,
      newMessage: '',
      userStatus: {},
      isUserTyping: false,
      typingTimeout: null,
      uploading: false,
      uploadProgress: 0,
      lastMessage: null,
      interactionsRef: null,
    };
  },
  computed: {
    totalUnreadMessages() {
      return this.interactions.reduce((total, interaction) => {
        return total + (interaction.unreadCount || 0);
      }, 0);
    },
    sortedInteractions() {
      return this.interactions.sort((a, b) => b.lastMessageDate - a.lastMessageDate);
    },
  },
  methods: {
   async getDeviceId() {
    const info = await Device.getId();
    return info.identifier; // This will return a unique device ID.
  },

  async requestNotificationPermissions() {
    try {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        this.loading = true;

        const messaging = getMessaging();
        const token = await getToken(messaging, {
          vapidKey: 'BGCHz5qHsrrI5SveIUNCs5OS2dsNNS1KIYPDAI8NKe_kcLPUDmuSHwKvUz-Q-AcN2Aw2YId8smvWiRfd6ewvirM'
        });

        const deviceId = await this.getDeviceId(); // Get device ID.
        const db = getFirestore();
        const messagingRef = collection(db, 'messaging');
        const messagingDoc = doc(messagingRef, this.$store.state.Auth.token.claims.user_id);
        const snapshot = await getDoc(messagingDoc);

        if (snapshot.exists()) {
          let tokenArray = snapshot.data().tokens || [];

          // Check if the token for this device already exists
          const existingDevice = tokenArray.find(entry => entry.deviceId === deviceId);

          if (existingDevice) {
            if (existingDevice.token !== token) {
              // Update the token if it has changed
              existingDevice.token = token;
              await updateDoc(messagingDoc, { tokens: tokenArray });
            }
          } else {
            // Add new device and token
            tokenArray.push({ deviceId, token });
            await updateDoc(messagingDoc, { tokens: tokenArray });
          }
        } else {
          // Create a new document if it doesn't exist
          await setDoc(messagingDoc, {
            tokens: [{ deviceId, token }]
          });
        }

        this.loading = false;
        this.dialogRequestPermission = false;

        logAuditEvent('notification', this.$store.state.Auth.token.claims.user_id, "Accepted permission");

      } else {
        this.loading = false;
        this.dialogRequestPermission = false;


        logAuditEvent('notification', this.$store.state.Auth.token.claims.user_id, "Not accepted permission");
      }

    } catch (e) {
      this.requestNotificationPermissions();

      console.log('Error requesting notification permissions:', e);
      this.$notify({
        title: 'Error',
        text: 'Error al solicitar permisos de notificación. Por favor, inténtelo de nuevo.',
        type: 'error'
      });

      logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, e);
    }
  },
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;

      if (this.isChatOpen) {
        this.requestNotificationPermissions();
      }
    },
    closeChat() {
      this.isChatOpen = false;
      this.selectedConversation = null;
    },
    goBack() {
      this.selectedConversation = null;
    },
    async loadInteractions() {
      const db = getDatabase();
      this.interactionsRef = dbRef(db, 'interactions');


      // Listen for interactions data
      onValue(this.interactionsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const interactionsArray = Object.keys(data).map((key) => ({
            ...data[key],
            id: key,
          }));
          // Sort interactions by lastMessageDate descending
          this.interactions = interactionsArray.sort(
            (a, b) => b.lastMessageDate - a.lastMessageDate
          );
        } else {
          this.interactions = [];
        }

        // Avoid processing if interactions list is empty
        if (this.interactions.length === 0) {
          return;
        }

        // Check if chat is not open
        if (!this.isChatOpen) {
          // If lastMessage is null, this is the initial load; set lastMessage without showing notification
          if (this.lastMessage === null) {
            this.lastMessage = this.interactions[0].lastMessage;
          }
          // If lastMessage is different from the latest message, show notification
          else if (this.lastMessage !== this.interactions[0].lastMessage) {
            this.lastMessage = this.interactions[0].lastMessage;
            this.showNotification(this.interactions[0]);
          }
          // If messages are the same, do nothing
        } else {
          // Update lastMessage when chat is open to keep it in sync
          this.lastMessage = this.interactions[0].lastMessage;
        }
      });


      // Set admin online and listen for statuses
      this.setUserOnline(
        this.$store.state.Auth.token.claims.user_id
      );
      this.listenForStatusUpdates();
    },
    async selectConversation(conversation) {
      this.selectedConversation = conversation;

      const db = getDatabase();
      const conversationRef = dbRef(db, `interactions/${conversation.id}`);
      update(conversationRef, { unreadCount: 0 });

      const messagesRef = dbRef(db, `interactions/${conversation.id}/messages`);

      onValue(messagesRef, (snapshot) => {
        let messages = [];
        const data = snapshot.val();
        if (data) {
          messages = Object.keys(data)
            .map((key) => ({ ...data[key], id: key }))
            .sort((a, b) => a.timestamp - b.timestamp);
        }
        this.$set(this.selectedConversation, 'messages', messages);

        this.$nextTick(() => {
          const chatContent = document.querySelector('.chat-content');

          if (chatContent) {
            chatContent.scrollTop = chatContent.scrollHeight;
          }

          const images = document.querySelectorAll('.chat-content img');
          images.forEach((img) => {
            img.addEventListener('load', () => {
              chatContent.scrollTop = chatContent.scrollHeight;
            });
          });
        });
      });

      this.markMessagesAsRead(conversation.id);
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        const message = {
          text: this.newMessage,
          fromUser: false,
          timestamp: Date.now(),
          type: 'text',
        };

        this.sendMessageToDatabase(message);

        // Reset typing status
        this.setTypingStatus(false);

        this.newMessage = '';
      }
    },
    async sendMessageToDatabase(message) {
      const db = getDatabase();
      const messagesRef = dbRef(db, `interactions/${this.selectedConversation.id}/messages`);
      const newMessageRef = push(messagesRef);
      set(newMessageRef, message);

      const conversationRef = dbRef(db, `interactions/${this.selectedConversation.id}`);
      update(conversationRef, {
        lastMessage: message.type === 'text' ? message.text : 'Sent a file',
        lastMessageDate: Date.now(),
      });
    },
    markMessagesAsRead(conversationId) {
      const db = getDatabase();
      const messagesRef = dbRef(db, `interactions/${conversationId}/messages`);

      get(messagesRef).then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();
          if (!data.readAt && data.fromUser) {
            const messageRef = childSnapshot.ref;
            update(messageRef, { readAt: Date.now() });
          }
        });
      });
    },
    setUserOnline(userId) {
      const db = getDatabase();
      const userStatusRef = dbRef(db, `/status/${userId}`);

      set(userStatusRef, {
        name: this.$store.state.Auth.token.claims.name,
        state: 'online',
        lastChanged: Date.now(),
      });
      onDisconnect(userStatusRef).set({
        name: this.$store.state.Auth.token.claims.name,
        state: 'offline',
        lastChanged: Date.now(),
      });
    },
    listenForStatusUpdates() {
      const db = getDatabase();
      const statusRef = dbRef(db, '/status');
      onValue(statusRef, (snapshot) => {
        this.userStatus = snapshot.val() || {};
      });
    },
    getStatus(userId) {
      return this.userStatus[userId]?.state === 'online' ? 'Online' : 'Offline';
    },
    getStatusClass(state) {
      return state === 'online' ? 'text-success' : 'text-secondary';
    },
    getStatusText(state) {
      return state === 'online' ? 'Online' : 'Offline';
    },
    getUserName(userId) {
      return this.userStatus[userId]?.name || 'Unknown';
    },
    handleTyping() {
      // Set typing status to true
      this.setTypingStatus(true);

      // Clear existing timeout
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);
      }

      // Set typing status to false after a delay
      this.typingTimeout = setTimeout(() => {
        this.setTypingStatus(false);
      }, 300); // Adjust delay as needed
    },
    setTypingStatus(isTyping) {
      if (!this.selectedConversation) return;
      const db = getDatabase();
      const typingRef = dbRef(db, `/typing/${this.selectedConversation.id}/admin`);

      set(typingRef, isTyping);
    },
    listenForUserTyping(conversationId) {
      const db = getDatabase();
      const typingRef = dbRef(db, `/typing/${conversationId}/user`);

      onValue(typingRef, (snapshot) => {
        this.isUserTyping = snapshot.val() || false;
      });
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.uploading = true;
      const storage = getStorage();
      const storageReference = storageRef(
        storage,
        `interactions/${this.selectedConversation.id}/${Date.now()}_${file.name}`
      );

      const uploadTask = uploadBytesResumable(storageReference, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error('File upload error:', error);
          this.uploading = false;
          this.uploadProgress = 0;
        },
        async () => {
          this.uploading = false;
          this.uploadProgress = 0;
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          let messageType = 'file';
          if (file.type.startsWith('image/')) {
            messageType = 'image';
          } else if (file.type.startsWith('video/')) {
            messageType = 'video';
          }

          const message = {
            fromUser: false,
            timestamp: Date.now(),
            type: messageType,
            fileUrl: downloadURL,
            fileName: file.name,
            fileType: file.type,
          };

          await this.sendMessageToDatabase(message);
        }
      );
    },
    downloadFile(url) {
      window.open(url, '_blank');
    },
    async showNotification(message) {
      const userName = this.getUserName(message.id);

      const storage = getStorage();
      const userId = message.id;
      const storageReference = storageRef(storage, `/profile/${userId}/profilePic.jpg`);
      const uploadedImageUrl = await getDownloadURL(storageReference);
      const userImage =  uploadedImageUrl;

      const notification = new Notification(userName, {
        body: message.lastMessage,
        icon: userImage,
      });

      notification.onclick = () => {
        //focus window
        window.focus();
        // Open chat window
        this.isChatOpen = true;/* 
        this.selectConversation(message); */
      };
    },
  },
  async mounted() {
    await this.loadInteractions();

  },
  beforeDestroy() {
    // Clean up typing status when component is destroyed

    off(this.interactionsRef);

    if (this.selectedConversation) {
      this.setTypingStatus(false);
    }
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
  },
};
</script>

<style scoped>
/* Floating Chat Button */
.floating-chat-btn {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
}

.small .floating-chat-btn {
  bottom: 86px !important;
  right: 16px !important;
}

/* Chat Dialog */
.chat-dialog-content {
  position: fixed;
  bottom: 80px;
  right: 24px;
  margin: 0;
  width: 400px;
  max-height: 70vh;
  overflow: hidden;
}

/* Chat Header */
.chat-header {
  flex-shrink: 0;
}

/* Chat Content */
.chat-content {
  height: 400px; /* Adjust based on header and input heights */
  overflow-y: auto;
  margin-bottom: 16px;
}

.chat-content.ismobile {
  height: calc(100vh - 144px) !important;
}

/* Message Styles */
.message-container {
  display: flex;
  margin: 8px;
}

.from-user {
  justify-content: flex-start;
}

.from-admin {
  justify-content: flex-end;
}

.typing-indicator {
  margin-left: 16px;
}

/* Message Input */
.message-input {
  flex-shrink: 0;
}

/* Selected Conversation */
.theme--light .selected {
  background-color: #f5f5f5;
}

.theme--dark .selected {
  background-color: #424242;
}

/* Status Colors */
.text-success {
  color: green;
}

.text-secondary {
  color: grey;
}


</style>


<style >

.chat-dialog-content{
        align-self: auto;
    right: 0px;
    position: absolute;
    bottom: 20px;
    box-shadow: 0px 0px ;
}

</style>