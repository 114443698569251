// audit.js

import {
  getFirestore,
  collection,
  doc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

const db = getFirestore();

/**
 * Log an event into the appropriate subcollection based on action type.
 *
 * @param {string} actionType - The type of action to log ('error', 'create', 'update').
 * @param {string} userId - The ID of the user performing the action.
 * @param {string} details - A description or additional details about the action.
 * @returns {Promise<void>}
 */
export async function logAuditEvent(actionType, userId, details) {
  try {
    if (
      ![
        "error",
        "create",
        "update",
        "delete",
        "read",
        "export",
        "checkin",
        "ai",
        "notification",
      ].includes(actionType)
    ) {
      throw new Error(`Invalid action type: ${actionType}`);
    }

    const logEntry = {
      userId: userId || "anonymous", // User ID or 'anonymous'
      details, // Details about the action
      timestamp: serverTimestamp(), // Timestamp of the log entry
      extraInfo: getUserInfo(),
    };

    // Reference to the appropriate document for the action
    const actionDocRef = doc(db, "log", actionType);

    // Save the log entry to the appropriate subcollection
    await addDoc(collection(actionDocRef, "entries"), logEntry);

    //console.log(`Audit log (${actionType}) created successfully:`, logEntry);
  } catch (error) {
    //console.error("Error logging audit event:", error);
  }
}

// Function to get user info
function getUserInfo() {
  const userInfo = {
    browser: getBrowserInfo(),
    os: getOSInfo(),
    screenSize: getScreenSize(),
    language: navigator.language,
    dateTime: new Date().toString(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return userInfo;
}

// Function to get browser info
function getBrowserInfo() {
  const ua = navigator.userAgent;
  let browserName = "Unknown";

  if (ua.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (ua.indexOf("SamsungBrowser") > -1) {
    browserName = "Samsung Internet";
  } else if (ua.indexOf("Opera") > -1 || ua.indexOf("OPR") > -1) {
    browserName = "Opera";
  } else if (ua.indexOf("Trident") > -1) {
    browserName = "Microsoft Internet Explorer";
  } else if (ua.indexOf("Edge") > -1) {
    browserName = "Microsoft Edge";
  } else if (ua.indexOf("Chrome") > -1) {
    browserName = "Google Chrome";
  } else if (ua.indexOf("Safari") > -1) {
    browserName = "Apple Safari";
  }

  return browserName;
}

// Function to get OS info
function getOSInfo() {
  const os = navigator.platform;
  let osName = "Unknown";

  if (os.indexOf("Win") > -1) {
    osName = "Windows";
  } else if (os.indexOf("Mac") > -1) {
    osName = "MacOS";
  } else if (os.indexOf("Linux") > -1) {
    osName = "Linux";
  } else if (os.indexOf("Android") > -1) {
    osName = "Android";
  } else if (os.indexOf("iOS") > -1) {
    osName = "iOS";
  }

  return osName;
}

// Function to get screen size
function getScreenSize() {
  return {
    width: screen.width,
    height: screen.height,
  };
}

// Example usage:
// logAuditEvent('create', 'user123', 'Created a new document in the users collection');
