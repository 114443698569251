<template>
  <v-app-bar app  id="nav" flat fixed  dense
    class="navBar" v-bind:class="{ 'isCapacitor': isCapacitor }">




    <!-- Conditional rendering for the "chevron left" icon -->
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-btn fab text small @click.stop="$emit('toggleDrawer');" style="margin-left: 0px;"
          v-show="$route.path != '/qr'">
          <v-icon v-if="!drawer">
            mdi-menu
          </v-icon>
          <v-icon v-else>
            mdi-close
          </v-icon>
        </v-btn>

    </template>
    <template v-else>
      <v-btn fab text small v-if="!['/', '/inicio'].includes($route.path)" @click="$router.go(-1);"
        v-show="$route.path != '/qr'">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
    </template>

    <v-spacer></v-spacer>

      Tomas Pereiro Entrenador
 <!--    <v-img @click="$router.push('/inicio')" style="cursor:pointer;" v-show="$route.path != '/qr'" alt="Logo"
      class="shrink ml-2" contain v-if="!($store.state.active_call || !$store.state.Auth.isLogged)"
       transition="scale-transition"
      v-bind:class="{ 'logoOpacity': $store.state.active_call }" height="40" width="120" /> -->

    <v-spacer></v-spacer>


    <v-btn style="visibility:hidden;" fab text small @click="$router.push('/settings')" v-show="$route.path != '/qr'">
      <v-icon>
        mdi-cog
      </v-icon>
    </v-btn>
<!-- 
    <v-badge v-show="$route.path != '/qr'" :content="1" color="red" overlap v-if="$store.state.Auth.isLogged"
      :value="computedNotifications">

      <v-btn fab text small @click="$emit('toggleDrawerRight');">
        <v-icon>{{
          !drawer2
            ? "mdi-bullhorn"
            : "mdi-close"
          }}</v-icon>
      </v-btn>
    </v-badge>

    <v-badge v-show="$route.path != '/qr'" :content="computedAlerts" color="red" overlap
      v-if="$store.state.Auth.isLogged && $props.alerts.length > 0" :value="computedAlerts">
      <v-btn fab text small @click="$emit('toggleDrawerRightAlerts');">
        <v-icon>{{
          !drawerAlerts
            ? "mdi-bell"
            : "mdi-close"
          }}</v-icon>

      </v-btn>
    </v-badge> -->
  </v-app-bar>
</template>

<script>
import Settings from "@/components/Settings.vue";
import Login from "./../models/login.js";
import ScreenShot from "./ScreenShot.vue";
import { Capacitor } from '@capacitor/core';
import moment from 'moment';

export default {
  props: {
    drawer: Boolean,
    drawer2: Boolean,
    drawerAlerts: Boolean,
    noticationUpdatedAt: Date,
    alerts: Array,
    news: String,
  },
  data() {
    return {
      fab: false,
      dialogConfirm: false,
      isCapacitor: Capacitor.isNativePlatform(),
    }
  },
  components: {
    ScreenShot,
    Settings,
  },
  methods: {
    async logout(bool) {
      if (bool) {
        await Login.logout();
        this.$router.push("/login");
        this.dialogConfirm = false;
      }
      if (this.$store.state.active_meeting) {
        this.dialogConfirm = true;
      } else {
        await Login.logout();
        this.$router.push("/login");
        this.dialogConfirm = false;
      }
    },
  },
  computed: {
    computedNotifications() {
      let noticationUpdatedAt = moment(this.noticationUpdatedAt, "YYYY-MM-DD HH:mm:ss");
      let lastLocalUpdate = moment(this.$store.state.lastNotificationUpdate, "YYYY-MM-DD HH:mm:ss");
      let diff = noticationUpdatedAt.diff(lastLocalUpdate, 'seconds');

      if (!this.$props.drawer2 && diff > 0 && this.$props.news.trim() != "") {
        return 1;
      } else {
        return 0;
      }
    },
    computedAlerts() {
      let totalUnseen = 0;



      let lastLocalUpdate = this.$store.state.lastAlertUpdate ? moment(this.$store.state.lastAlertUpdate, "YYYY-MM-DD HH:mm:ss") : moment("2020-01-01 00:00:00", "YYYY-MM-DD HH:mm:ss");

      this.alerts.forEach(alert => {
        let alertDate = moment(new Date(alert.date.seconds * 1000));
        if (alertDate.isAfter(lastLocalUpdate)) {
          totalUnseen++;
        }
      });

      return totalUnseen
    }
  },
  mounted() {

  }
};
</script>

<style lang="scss">
.isCapacitor {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
  background-color: var(--v-primary-base) !important;
  height: 100px !important;
}


.logoOpacity {
  opacity: 0.5 !important;
}


.v-speed-dial__list button.theme--light {
  background-color: #f5f5f5 !important;
}

.v-speed-dial__list button.theme--dark {
  background-color: #272727 !important;
}

.navBar .v-badge__badge {
  inset: unset !important;
}
</style>